
<template>
  <div>
    {{ examen }}
  </div>
</template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
      props: {object: {type: Object, required: true}},
      computed:{
          ...mapGetters({
              examens: 'sanitaire/examens'
          }),
          examen(){
              if(this.object !== null) 
              {
                let e = this.examens.find(item => item.uid === this.object.examen)
                if(e) return e.libelle
                return '-'
              }
              return '-'
          }
      }
  }
  </script>
  
  <style>
  
  </style>